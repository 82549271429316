<template>
  <div class="listDetail" v-html="pageCont">
  </div>
</template>

<script>
export default {
  props: {
    listId: {
      default: null,
      typeof: [String, Boolean]
    }
  },
  data() {
    return {
      list: [],
      pageCont: ''
    }
  },
  mounted() {
    var _this = this

    _this.getList()
  },
  methods: {
    // 查列表
    async getList() {
      var _this = this;
      
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: _this.listId, page: 1, pageSize: 10});
      _this.getDetail(data.data.list[0].id)
    },
    // 查详情
    async getDetail(id) {
      var _this = this;

      _this.loading = true
      var { data } = await _this.$http.post( _this.apiUrl + "/news/getNewsDetail", { id: id });
      _this.pageCont = data.data.content;
      _this.loading = false
    },
  }
}
</script>

<style>
.listDetail {
  padding: 20px 0;
}
</style>