<template>
  <div class="listCont">
    <ul class="listList">
      <li v-for="(item, index) in listData" :key="index">
        <a class="clearfloat" :href="item.is_link === 1 ? item.link_url : `${urlName}detail?id=${item.id}`" target="_blank">
          <div class="time fr">
            <h3>{{dayjs(item.create_time).format('MM-DD')}}</h3>
            <p>{{dayjs(item.create_time).format('YYYY')}}</p>
          </div>
          <div class="inner single-line">
            {{item.title}}
          </div>
        </a>
      </li>
    </ul>
    <div class="listPage">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="pageNum"
        :page-size="pageSize"
        :total="total"
        @current-change="currentChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listId: {
      default: null,
      typeof: [String, Boolean]
    }
  },
  data() {
    return {
      listData: [],
      content: '',
      pageSize: 10,
      pageNum: 1,
      total: 0,
    }
  },
  mounted() {
    var _this = this

    _this.getList()
  },
  methods: {
    currentChange(e){
      var _this = this;
      _this.pageNum = e
      _this.getList()
    },
    // 查列表
    async getList() {
      var _this = this;
      
      _this.loading = true
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: _this.listId, page: _this.pageNum, pageSize: _this.pageSize});
      // console.log('data :>> ', data);
      _this.listData = data.data.list
      _this.total = data.data.total
      _this.loading = false
    },
  }
}
</script>

<style>

</style>