<template>
  <div class="listOrginazation">
    <div class="listOrginazationRow">
      <div class="listOrginazationColTitle listOrginazationColTitle001">
        <span>公司领导</span>
      </div>
      <div class="listOrginazationColWrap">
        <a v-for="(item,index) in list01" :href="item.is_link === 1 ? item.link_url : `${urlName}detail?id=${item.id}`" target="_blank" :key="index">
          {{ item.title }}
        </a>
      </div>
    </div>
    <div class="listOrginazationRow">
      <div class="listOrginazationColTitle listOrginazationColTitle002">
        <span>内设机构</span>
      </div>
      <div class="listOrginazationColWrap">
        <a v-for="(item,index) in list02" :href="item.is_link === 1 ? item.link_url : `${urlName}detail?id=${item.id}`" target="_blank" :key="index">
          {{ item.title }}
        </a>
      </div>
    </div>
    <div class="listOrginazationRow">
      <div class="listOrginazationColTitle listOrginazationColTitle003">
        <span>外派机构</span>
      </div>
      <div class="listOrginazationColWrap">
        <p v-for="(item,index) in list03" :key="index">
          <a :href="item.is_link === 1 ? item.link_url : `${urlName}detail?id=${item.id}`" target="_blank" :key="index">
            {{ item.title }}
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      content: '',
      list01: [],
      list02: [
        // {
        //   title: '工会'
        // },
        // {
        //   title: '纪检监察科'
        // },
        // {
        //   title: '人劳科'
        // },
        // {
        //   title: '审计科'
        // },
        // {
        //   title: '质量安全科'
        // },
        // {
        //   title: '财务科'
        // },
        // {
        //   title: '工程建设管理科'
        // },
        // {
        //   title: '总工程师办公室'
        // },
        // {
        //   title: '党办'
        // },
        // {
        //   title: '综合办公室'
        // },
      ],
      list03: [
        // {
        //   title: '甘肃公航旅天庄高速公路管理有限公司'
        // },
        // {
        //   title: 'G30天水至定西高速公路维修完善工程项目管理办公室'
        // },
        // {
        //   title: '十堰至天水高速公路项目管理办公室'
        // },
        // {
        //   title: '甜水堡（宁甘界）经庆城至永和（甘陕界）公路宁县段项目管理办公室'
        // },
        // {
        //   title: '甜水堡（宁甘界）经庆城至永和（甘陕界）公路环县段项目管理办公室'
        // },
        // {
        //   title: '平凉（华亭）至天水高速公路天水段项目管理办公室'
        // },
        // {
        //   title: '平凉（华亭）至天水高速公路平凉段项目管理办公室'
        // },
      ],
    }
  },
  mounted() {
    this.getList()
    this.getList01()
    this.getList02()
  },
  methods: {
    async getList() {
      var _this = this;
      
      _this.loading = true
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: 438, page: 1, pageSize: 100});
      // console.log('data :>> ', data);
      _this.list01 = data.data.list
    },
    async getList01() {
      var _this = this;
      
      _this.loading = true
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: 473, page: 1, pageSize: 100});
      // console.log('data :>> ', data);
      _this.list02 = data.data.list
    },
    async getList02() {
      var _this = this;
      
      _this.loading = true
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: 474, page: 1, pageSize: 100});
      // console.log('data :>> ', data);
      _this.list03 = data.data.list
    },
  }
}
</script>

<style>
.listOrginazation {
  padding: 20px 40px;
}
.listOrginazationRow {
  border: 1px solid #ccc;
  border-top: none;
  /* border-left: none; */
  display: flex;
  align-items: center;
}
.listOrginazationRow:first-child {
  border-top: 1px solid #ccc;
}
.listOrginazationCol {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
.listOrginazationColTitle {
  width: 140px;
  height: 100%;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.listOrginazationColTitle span {
  display: block;
  width: 140px;
}
.listOrginazationColWrap {
  padding: 10px 15px;
  font-size: 18px;
  border-left: 1px solid #ccc;
}
.listOrginazationColWrap a {
  display: inline-block;
  margin: 10px 12px;
  color: #555;
}
.listOrginazationColTitle01 {
  height: 115px;
  line-height: 115px;
}
.listOrginazationColTitle02 {
  height: 115px;
  line-height: 115px;
}
.listOrginazationColTitle03 {
  height: 350px;
  line-height: 350px;
}
</style>