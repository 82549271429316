<template>
  <div class="listPic">
    <ul class="listListPic clearfloat">
      <li v-for="(item, index) in listData" :key="index">
        <a class="clearfloat" :href="item.is_link === 1 ? item.link_url : `${urlName}detail?id=${item.id}`" target="_blank">
          <div class="listImg">
            <el-image
              style="width: 100%; height: 100%"
              :src="item.img"
              fit="cover"></el-image>
          </div>
          <div class="inner single-line">
            {{item.title}}
          </div>
        </a>
      </li>
    </ul>
    <div class="listPage">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="pageNum"
        :page-size="pageSize"
        :total="total"
        @current-change="currentChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listId: {
      default: null,
      typeof: [String, Boolean]
    }
  },
  data() {
    return {
      listData: [],
      content: '',
      pageSize: 20,
      pageNum: 1,
      total: 0,
      // listData: [
      //   {
      //     title: '省旅投集团举办首期抖音短视频省旅投集团举办首期抖音短视频省旅投集团举办首期抖音短视频',
      //     img: require('../assets/img/home_vedio_img01.jpg'),
      //     id: '1',
      //   },
      //   {
      //     title: '集团公司领导赴焉支山景区调研',
      //     img: require('../assets/img/home_vedio_img02.jpg'),
      //     id: '2'
      //   },
      //   {
      //     title: '省旅投集团举办首期抖音短视频省旅投集团举办首期抖音短视频省旅投集团举办首期抖音短视频',
      //     img: require('../assets/img/home_vedio_img01.jpg'),
      //     id: '1',
      //   },
      //   {
      //     title: '省旅投集团举办首期抖音短视频省旅投集团举办首期抖音短视频省旅投集团举办首期抖音短视频',
      //     img: require('../assets/img/home_vedio_img01.jpg'),
      //     id: '1',
      //   },
      //   {
      //     title: '集团公司领导赴焉支山景区调研',
      //     img: require('../assets/img/home_vedio_img02.jpg'),
      //     id: '2'
      //   },
      //   {
      //     title: '省旅投集团举办首期抖音短视频省旅投集团举办首期抖音短视频省旅投集团举办首期抖音短视频',
      //     img: require('../assets/img/home_vedio_img01.jpg'),
      //     id: '1',
      //   },
      //   {
      //     title: '集团公司领导赴焉支山景区调研',
      //     img: require('../assets/img/home_vedio_img02.jpg'),
      //     id: '2'
      //   }
      // ]
    }
  },
  mounted() {
    var _this = this

    _this.getList()
  },
  methods: {
    currentChange(e){
      var _this = this;
      _this.pageNum = e
      _this.getList()
    },
    // 查列表
    async getList() {
      var _this = this;
      _this.listData = []
      _this.loading = true
      // var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: _this.listId, page: _this.pageNum, pageSize: _this.pageSize});
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsListImg', {group_id: _this.listId, page: _this.pageNum, pageSize: _this.pageSize});
      // console.log('data :>> ', data);
      _this.listData = data.data.list
      _this.total = data.data.total
      _this.loading = false
    },
  }
}
</script>

<style>
.listListPic {
  padding: 20px 0;
}
</style>